import React from "react";
import Layout from "../components/layout/Layout.js";
import { Column100 } from "../components/column/Column.js";
import { BlogRow } from '../components/row/Row.js'
import { TextMedium } from '../components/text/Text'
import { BlogTitle } from '../components/title/Title'
import { Section } from "../components/section/Section.js";
import Seo from '../components/seo'

function DownloadSuccess () {
  return (
    <Layout>
      <Seo title="Download Successful" />
      <Section>
        <BlogRow isCentered>
          <Column100>
            <BlogTitle isPrimary title="Thanks for downloading our template!"/>
            <TextMedium textMedium="Please check your email for the download link." />
            <TextMedium textMedium="If you have questions regarding this template or our services, 
            you can always email us at contact@schoolofcontent.net." />
            <TextMedium textMedium="Thanks and good luck with your content strategy plan!" />
          </Column100>
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default DownloadSuccess
